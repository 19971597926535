function pushDataLayerEventAsync(event: string) {
  return new Promise<void>((resolve) => {
    if (
      window.dataLayer == null ||
      Object.keys(window.dataLayer).length === 0
    ) {
      // Development environment where GTM is not active
      // eslint-disable-next-line no-console
      console.log("Pushing to dataLayer: " + event);
      resolve();
    } else {
      window.dataLayer?.push({
        event: event,
        eventCallback: resolve,
      });
    }
  });
}

function pushDataLayerEvent(event: string) {
  if (!window.dataLayer || Object.keys(window.dataLayer).length === 0) {
    // Development environment where GTM is not active
    // eslint-disable-next-line no-console
    console.log("Pushing to dataLayer: " + event);
  } else {
    window.dataLayer?.push({
      event: event,
    });
  }
}

export { pushDataLayerEventAsync, pushDataLayerEvent };

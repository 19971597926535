import { notFound } from "next/navigation";
import MonitoringService from "@lib/MonitoringService";

type ServerResponse<T> =
  | { status: "aborted" }
  | {
      status: "error";
      statusCode: number;
      errorBody?: string;
      errorCode?: string;
    }
  | { status: "ok"; statusCode: number; value: T };

async function render<T>(
  p: Promise<Response>,
  mapFn?: (e: T) => T,
): Promise<ServerResponse<T>> {
  try {
    const mapper = mapFn ? mapFn : (e: T) => e;
    const response = await p;
    if (response.ok) {
      return renderValue<T>(response.status, mapper(await response.json()));
    } else {
      try {
        // Attempt to parse the response body as JSON, then convert it to a string
        const jsonResponse = await response.json();
        const errorCode = jsonResponse.error_code;
        const errorBody = JSON.stringify(jsonResponse);
        return renderError<T>(response.status, errorBody, errorCode);
      } catch (e) {
        return renderError<T>(response.status, "Failed to parse error body");
      }
    }
  } catch (e) {
    if ((e as Error).name === "AbortError") {
      // Handle abort error
      return { status: "aborted" };
    }
    MonitoringService.captureException(e);
    return renderError();
  }
}

function renderValue<T>(statusCode: number, value: T): ServerResponse<T> {
  return {
    statusCode,
    status: "ok",
    value,
  };
}

function renderError<T>(
  statusCode: number = 0,
  errorBody?: string,
  errorCode?: string,
): ServerResponse<T> {
  return {
    status: "error",
    statusCode,
    errorBody,
    errorCode,
  };
}

function getValueOrThrow<T>(p: Promise<ServerResponse<T>>) {
  return p.then((response) => {
    return unwrapValueOrThrow(response);
  });
}

function unwrapValueOrThrow<T>(response: ServerResponse<T>) {
  if (response.status === "error") {
    if (response.statusCode === 404) {
      notFound();
    }
    // This is an error between 2 servers, so we should log it
    MonitoringService.captureMessage(
      "Server response error: " + response.statusCode,
      {
        extra: response,
      },
    );
    throw new Error(`Server response error: ${response.statusCode}`);
  } else if (response.status === "aborted") {
    throw new Error("Request was aborted");
  }
  return response.value;
}

export {
  getValueOrThrow,
  render,
  renderError,
  renderValue,
  unwrapValueOrThrow,
};
export type { ServerResponse };

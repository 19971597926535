import {
  ampli,
  AuthPromptViewedProperties as AmpliAuthPromptViewedProperties,
} from "@/analytics/ampli";
import { SourcePage } from "./sourcePage";
import { SourceFlow } from "./sourceFlow";
import { SourceAction } from "./sourceAction";
import { pushDataLayerEventAsync } from "@/analytics/analytics.datalayer";

type AuthPromptViewedProperties = Omit<
  AmpliAuthPromptViewedProperties,
  "source_page" | "source_flow" | "source_action"
> & {
  source_page: SourcePage;
  source_flow: SourceFlow;
  source_action?: SourceAction;
};

function trackAuthPromptViewed(props: AuthPromptViewedProperties) {
  ampli.authPromptViewed(props);
}

function trackRegistrationStarted() {
  ampli.registerAccountStarted();
}

function trackRegistrationFinished() {
  ampli.registerAccountFinished().promise;
}

function trackRegistrationConversion(registrationEvent: string) {
  return pushDataLayerEventAsync(registrationEvent);
}

type AuthStartedProps = {
  has_contact_intent: boolean;
  auth_kind: "signup" | "login";
};

function trackAuthStarted(props: AuthStartedProps) {
  ampli.authStarted({
    has_therapist_referral: props.has_contact_intent,
    auth_kind: props.auth_kind,
  });
}

export type AuthPopupClosedError =
  | "unauthorized"
  | "invalid_token"
  | "fetch_session_error";
type AuthPopupClosedProps = {
  duration: number;
  success: boolean;
  error?: AuthPopupClosedError;
};

function trackAuthPopupClosed(props: AuthPopupClosedProps) {
  ampli.authPopupClosed(props);
}

export {
  trackAuthStarted,
  trackRegistrationFinished,
  trackRegistrationStarted,
  trackAuthPromptViewed,
  trackAuthPopupClosed,
  trackRegistrationConversion,
};

import { ampli } from "@/analytics/ampli";
import * as auth from "@/analytics/analytics.auth";
import * as directory from "@/analytics/analytics.directory";
import { MyUser } from "@/api/entities/myUser";
import { withSilentTimeout } from "@/utils/PromiseUtils";
import { loadAmplitude } from "./amplitude";
import { loadGTM } from "./googletagmamager";
import { showConsentSettings } from "./usercentrics";

function load() {
  loadGTM();
  loadAmplitude();
}

function askPrivacyConsent() {
  // On first visit, usercentrics takes care of it, this is intended
  // to be used when the user wants to change heir settings later.
  showConsentSettings();
}

async function flushWithTimeout() {
  await withSilentTimeout(3000, ampli.flush().promise);
}

interface UserData {
  kind: "therapist" | "client";
  email?: string;
}

async function identify(user: MyUser | null) {
  const isProduction = process.env.NEXT_PUBLIC_APP_ENV === "production";

  if (user) {
    const userData: UserData = {
      kind: userAnalyticsKind(user.kind),
    };

    if (!isProduction) {
      userData.email = user.email;
    }

    ampli.identify(user.analytics_user_id, userData);
  } else {
    ampli.identify(undefined, { kind: userAnalyticsKind("Client") });
  }
}

function userAnalyticsKind(userKind: MyUser["kind"]): "therapist" | "client" {
  switch (userKind) {
    case "Client":
      return "client";
    case "Therapist":
      return "therapist";
    default:
      throw new Error(`unknown user kind:${userKind}`);
  }
}

export { askPrivacyConsent, auth, directory, flushWithTimeout, identify, load };

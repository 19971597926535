function withSilentTimeout(ms: number, promise: Promise<unknown>) {
  // Create a promise that resolves in <ms> milliseconds
  const timeout = new Promise<void>((resolve) => {
    const id = setTimeout(() => {
      clearTimeout(id);
      resolve();
    }, ms);
  });

  // Returns a race between our timeout and the passed in promise
  return Promise.race([promise, timeout]);
}

export { withSilentTimeout };

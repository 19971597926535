import { SupportedLocales } from "@/i18n/i18n.config";
import { navigateToExternalUrl } from "@/utils/NavigationUtils";

async function navigateToDashboard() {
  navigateToExternalUrl(`${process.env.NEXT_PUBLIC_DASHBOARD_URL}`);
}

async function navigateToDashboardAfterInquiry(locale: SupportedLocales) {
  navigateToExternalUrl(
    `${process.env.NEXT_PUBLIC_DASHBOARD_URL}/home?locale=${locale}&inquiry_sent=1`,
  );
}

async function navigateToDashboardAfterFavoriting(locale: SupportedLocales) {
  navigateToExternalUrl(
    `${process.env.NEXT_PUBLIC_DASHBOARD_URL}/home?locale=${locale}&favorite_added=1`,
  );
}

export {
  navigateToDashboardAfterFavoriting,
  navigateToDashboardAfterInquiry,
  navigateToDashboard,
};

"use client";
import React, {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useState,
} from "react";
import { useUser } from "@auth0/nextjs-auth0/client";
import MonitoringService from "@lib/MonitoringService";
import { UserSession } from "@/api/entities/userSession";
import { render } from "@/api/api";
import { auth, identify } from "@/analytics";
import { AuthPopupClosedError } from "@/analytics/analytics.auth";

const UserSessionContext = createContext<ICUserSessionValue | null>(null);

type ICUserSessionValue = {
  session: UserSession;
  sessionError?: Error;
  isLoadingSession: boolean;
  openLoginPopup: (params?: Record<string, string>) => void;
  openSignupPopup: (params?: Record<string, string>) => void;
};

const EMPTY_SESSION = Object.freeze({ user: null });

export function UserSessionProvider({
  children,
}: {
  children: React.ReactNode;
}) {
  const {
    user: auth0User,
    isLoading: isAuth0Loading,
    error: auth0Error,
  } = useUser();
  const [session, setSession] = useState<UserSession>(EMPTY_SESSION);
  const [isLoadingSession, setIsLoading] = useState(isAuth0Loading);
  const [sessionError, setError] = useState(auth0Error);

  const fetchSession = useCallback(async (authStartedAt?: number) => {
    // We do not want to check the auth0User here, as we may be triggering this after the login window closes, in which
    // case, we do not have the auth0User. For this case we want to go directly to our api to check the session

    setIsLoading(true);

    // If this fetch is happening post auth, we want to report the result to know how its working in the wild (we can remove this when have confidence in the flow)
    const reportPostAuthResult = (error?: AuthPopupClosedError) => {
      if (authStartedAt) {
        auth.trackAuthPopupClosed({
          duration: Date.now() - authStartedAt,
          success: !error,
          error: error,
        });
      }
    };

    const sessionRes = await render<UserSession>(fetch("/api/user/session"));
    setIsLoading(false);
    if (sessionRes.status === "error") {
      MonitoringService.addBreadcrumb({
        category: "auth",
        message: "User session invalid: " + sessionRes.errorBody,
        level: "info",
      });
      MonitoringService.setUser(null);
      setError(new Error("Failed to fetch session: " + sessionRes.errorBody));
      setSession(EMPTY_SESSION);
      reportPostAuthResult("fetch_session_error");
    } else if (sessionRes.status === "ok") {
      setError(undefined);
      setSession(sessionRes.value);

      if (sessionRes.value.user) {
        MonitoringService.setUser({
          id: sessionRes.value.user.id,
          email: sessionRes.value.user.email,
        });
      } else {
        MonitoringService.setUser(null);
        MonitoringService.captureMessage("No user in session");
      }
      reportPostAuthResult();
    }
  }, []);

  useEffect(() => {
    if (!isAuth0Loading) {
      if (!auth0User) {
        MonitoringService.addBreadcrumb({
          category: "auth",
          message:
            "No auth0 user present. Error: " + JSON.stringify(auth0Error),
          level: "info",
        });
        MonitoringService.setUser(null);
        setSession(EMPTY_SESSION);
        setError(auth0Error);
        setIsLoading(false);
      } else {
        fetchSession();
      }
    }
  }, [fetchSession, auth0User, isAuth0Loading, auth0Error]);

  useEffect(() => {
    identify(session.user || null);
  }, [session]);

  const openAuthPopup = useCallback(
    (endpoint: string, params?: Record<string, string>) => {
      if (session.user) {
        // Already logged in
        MonitoringService.captureMessage(
          `Attempted to open ${endpoint} popup while already logged in`,
        );
        return;
      }

      const authStartedAt = Date.now();

      const returnUrl = encodeURIComponent(
        process.env.NEXT_PUBLIC_APP_POPUP_AUTH_RETURN_TO_URL!.toString(),
      );

      let url = `/api/auth/${endpoint}?return_to=${returnUrl}`;

      // Convert params object to query string and append to the URL
      if (params) {
        const queryString = new URLSearchParams(params).toString();
        url += `&${queryString}`;
      }

      const width = 450,
        height = 650;
      const left = (window.screen.width - width) / 2;
      const top = (window.screen.height - height) / 2;
      const popup = window.open(
        url,
        endpoint,
        `width=${width},height=${height},top=${top},left=${left}`,
      );

      if (!popup) {
        MonitoringService.captureMessage(`Failed to open ${endpoint} popup`);
        window.location.href = url;
        return;
      }

      const timer = setInterval(() => {
        if (popup.closed) {
          clearInterval(timer);
          fetchSession(authStartedAt);
        }
      }, 500);
    },
    [fetchSession, session.user],
  );

  const openLoginPopup = useCallback(
    (params?: Record<string, string>) => {
      openAuthPopup("login", params);
    },
    [openAuthPopup],
  );

  const openSignupPopup = useCallback(
    (params?: Record<string, string>) => {
      openAuthPopup("signup", params);
    },
    [openAuthPopup],
  );

  const value = {
    session,
    isLoadingSession,
    sessionError,
    openLoginPopup,
    openSignupPopup,
  };

  return (
    <UserSessionContext.Provider value={value}>
      {children}
    </UserSessionContext.Provider>
  );
}

export function useUserSession(): ICUserSessionValue {
  const context = useContext(UserSessionContext);
  if (!context) {
    throw new Error("useUserSession must be used within a UserSessionProvider");
  }
  return context;
}

export { UserSessionContext };

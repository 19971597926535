declare global {
  interface Window {
    // gtm.js adds the dataLayer array to the window
    UC_UI?: { showFirstLayer: () => void };
  }
}

function showConsentSettings() {
  // Assume UC_UI is present
  if (!window.UC_UI) {
    throw new Error("usercentrics is not loaded");
  }
  window.UC_UI.showFirstLayer();
}

export { showConsentSettings };

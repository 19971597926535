import { flushWithTimeout } from "@/analytics";

async function navigateToExternalUrl(url: string) {
  // Flush all events before leaving the app
  await flushWithTimeout();

  // https://nextjs.org/docs/pages/api-reference/functions/use-router#routerpush
  // You don't need to use router.push for external URLs. window.location is better suited for those cases.
  window.location.href = url;
}

async function closeAfterFlushing() {
  // Flush all events before leaving the app
  await flushWithTimeout();
  window.close();
}

export { navigateToExternalUrl, closeAfterFlushing };

import {
  type CaptureContext,
  type Severity,
  type SeverityLevel,
  UserFeedback,
} from "@sentry/types";
import { ExclusiveEventHintOrCaptureContext } from "@sentry/core/types/utils/prepareEvent";
import {
  addBreadcrumb as sentryAddBreadcrumb,
  Breadcrumb,
  captureException as sentryCaptureException,
  captureMessage as sentryCaptureMessage,
  captureUserFeedback as sentryCaptureUserFeedback,
  setUser as sentrySetUser,
} from "@sentry/nextjs";

class MonitoringService {
  static setUser(user: { id: string; email: string } | null) {
    if (process.env.NEXT_PUBLIC_SENTRY_ENABLED === "false") {
      return;
    }

    sentrySetUser(
      user
        ? {
            id: user.id,
            email: user.email,
          }
        : null,
    );
  }

  static clearUser() {
    if (process.env.NEXT_PUBLIC_SENTRY_ENABLED === "false") {
      return;
    }
    sentrySetUser(null);
  }

  static captureException(
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    exception: any,
    hint?: ExclusiveEventHintOrCaptureContext,
  ): string | undefined {
    if (process.env.NEXT_PUBLIC_SENTRY_ENABLED === "false") {
      // eslint-disable-next-line no-console
      console.error("[ERROR (captured)]", exception);
      return undefined;
    }
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    sentryCaptureException(exception, hint as any);
  }

  static captureMessage(
    message: string,
    captureContext?: CaptureContext | SeverityLevel | Severity | undefined,
  ): string | undefined {
    if (process.env.NEXT_PUBLIC_SENTRY_ENABLED === "false") {
      // eslint-disable-next-line no-console
      console.error("[MESSAGE (captured)]", message);
      return undefined;
    }

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    return sentryCaptureMessage(message, captureContext as any);
  }

  static captureUserFeedback(userFeedback: UserFeedback) {
    if (process.env.NEXT_PUBLIC_SENTRY_ENABLED === "false") {
      return;
    }
    sentryCaptureUserFeedback(userFeedback);
  }

  static addBreadcrumb(breadcrumb: Breadcrumb) {
    if (process.env.NEXT_PUBLIC_SENTRY_ENABLED === "false") {
      return;
    }
    sentryAddBreadcrumb(breadcrumb);
  }
}

export default MonitoringService;

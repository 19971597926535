declare global {
  interface Window {
    // gtm.js adds the dataLayer array to the window
    dataLayer?: object[];
  }
}

const GTM_ID = "GTM-T4JD6BB";

// GTM script execution is deferred, waiting on the user's consent.
//
// The dataLayer is used to capture events in memory, and only report them
// if and when the user gives their consent (GDPR).
function loadGTM() {
  if (typeof window !== "undefined") {
    window.dataLayer = window.dataLayer || [];
  }
}

export { GTM_ID, loadGTM };

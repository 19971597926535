"use client";
import React, {
  createContext,
  ReactNode,
  useCallback,
  useContext,
  useEffect,
  useState,
} from "react";
import MonitoringService from "@lib/MonitoringService";
import { render } from "@/api/api";
import { useUserSession } from "@/contexts/UserSession";
import { SourcePage } from "@/analytics/sourcePage";
import { useSupportedLocale } from "@/hooks/useLocale";
import { navigateToDashboardAfterFavoriting } from "@/helpers/AngularDashboardNavigator";
import { directory } from "@/analytics";

export enum FavoriteStatus {
  Loading = "LOADING",
  NotAvailable = "NOT_AVAILABLE",
  NotLoggedIn = "NOT_LOGGED_IN",
  WrongUserType = "WRONG_USER_TYPE",
  Available = "AVAILABLE",
}

type ChangeFavoriteRequest = {
  userId: string;
  sourcePage: SourcePage;
};

interface FavoritesContextProps {
  favorites: string[];
  addFavorite: (
    request: ChangeFavoriteRequest,
    forwardToDashboard: boolean,
  ) => Promise<void>;
  removeFavorite: (request: ChangeFavoriteRequest) => Promise<void>;
  status: FavoriteStatus;
  setPendingFavorite: (request: ChangeFavoriteRequest) => void;
  isAFavorite: (userId: string) => boolean;
}

const FavoritesContext = createContext<FavoritesContextProps | undefined>(
  undefined,
);

interface FavoritesProviderProps {
  children: ReactNode;
}

export const FavoritesProvider: React.FC<FavoritesProviderProps> = ({
  children,
}) => {
  const [favorites, setFavorites] = useState<string[]>([]);
  const [status, setStatus] = useState<FavoriteStatus>(FavoriteStatus.Loading);
  const [pendingFavorite, setPendingFavorite] = useState<
    ChangeFavoriteRequest | undefined
  >();

  const {
    session,
    isLoadingSession: isSessionLoading,
    sessionError: sessionError,
  } = useUserSession();

  const locale = useSupportedLocale();

  useEffect(() => {}, [pendingFavorite]);

  useEffect(() => {
    if (isSessionLoading || sessionError) {
      setStatus(FavoriteStatus.NotAvailable);
    } else if (!session.user) {
      setStatus(FavoriteStatus.NotLoggedIn);
    } else if (session.user.kind !== "Client") {
      setStatus(FavoriteStatus.WrongUserType);
      setPendingFavorite(undefined);
    }
  }, [session, isSessionLoading, sessionError]);

  const addFavorite = useCallback(
    async (
      request: ChangeFavoriteRequest,
      forwardToDashboard: boolean = false,
    ) => {
      // Optimistically update the state
      setFavorites((prev) => [...prev, request.userId]);

      try {
        const response = await fetch("/api/favorites", {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({ favoritee_id: request.userId }),
        });

        if (response.ok) {
          directory.savedToFavorites({
            therapist_user_id: request.userId,
            source_page: request.sourcePage,
          });
          if (forwardToDashboard) {
            navigateToDashboardAfterFavoriting(locale);
          }
        } else {
          // Revert the optimistic update if the request fails
          setFavorites((prev) => prev.filter((id) => id !== request.userId));
        }
      } catch (e) {
        // Revert the optimistic update if an exception occurs
        setFavorites((prev) => prev.filter((id) => id !== request.userId));
        MonitoringService.captureException(e);
      }
    },
    [locale],
  );

  useEffect(() => {
    const fetchFavorites = async () => {
      if (
        isSessionLoading ||
        sessionError ||
        !session.user ||
        session.user.kind != "Client"
      )
        return;
      try {
        const response = await render<string[]>(fetch("/api/favorites"));
        if (response.status == "ok") {
          setFavorites(response.value);
          setStatus(FavoriteStatus.Available);
          if (pendingFavorite) {
            // forward to dashboard if this is the first time the user is favoriting
            const forwardToDashboard = response.value.length == 0;
            addFavorite(pendingFavorite, forwardToDashboard);
            setPendingFavorite(undefined);
          }
        } else {
          setStatus(FavoriteStatus.NotAvailable);
        }
      } catch (e) {
        MonitoringService.captureException(e);
        setStatus(FavoriteStatus.NotAvailable);
      }
    };
    fetchFavorites();
  }, [isSessionLoading, sessionError, session, pendingFavorite, addFavorite]);

  const removeFavorite = async (request: ChangeFavoriteRequest) => {
    // Optimistically update the state
    setFavorites((prev) => prev.filter((id) => id !== request.userId));

    try {
      const response = await fetch(`/api/favorites/${request.userId}`, {
        method: "DELETE",
      });

      if (response.ok) {
        directory.removedFromFavorites({
          therapist_user_id: request.userId,
          source_page: request.sourcePage,
        });
      } else {
        // Revert the optimistic update if the request fails
        setFavorites((prev) => [...prev, request.userId]);
      }
    } catch (e) {
      // Revert the optimistic update if an exception occurs
      setFavorites((prev) => [...prev, request.userId]);
      MonitoringService.captureException(e);
    }
  };

  const isAFavorite = (userId: string) => favorites.includes(userId);

  return (
    <FavoritesContext.Provider
      value={{
        favorites,
        addFavorite,
        removeFavorite,
        status,
        setPendingFavorite,
        isAFavorite,
      }}
    >
      {children}
    </FavoritesContext.Provider>
  );
};

export const useFavorites = () => {
  const context = useContext(FavoritesContext);
  if (!context) {
    throw new Error("useFavorites must be used within a FavoritesProvider");
  }
  return context;
};
